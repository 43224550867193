<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>合同管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/ContractTList' }">合同模板列表</el-breadcrumb-item>
            <el-breadcrumb-item>添加合同模板</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-alert title="添加合同模板" type="info" center show-icon :closable="true"> </el-alert>
            <el-steps align-center :space="200" :active="ActiveIndex - 0" 
                finish-status="success">
                    <el-step title="基本信息"></el-step>
                    <el-step title="课包信息"></el-step>
                    <el-step title="上课日期"></el-step>
                    <el-step title="合同文件"></el-step>
                    <el-step title="完成"></el-step>
            </el-steps>
            <el-form :model="AddContractTemplateForm" :rules="AddContractTemplateFormRules"
                ref="AddContractTemplateFormRef" label-width="100px" label-position="left">
                <el-tabs v-model="ActiveIndex" :tab-position="'left'" 
                :before-leave="BeforeTabLeave"
                @tab-click="TabClick">
                    <!--合同基本信息-->
                    <el-tab-pane label="基本信息" name="0">
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="编号" prop="Code">
                                    <el-input v-model="AddContractTemplateForm.Code"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="名称" prop="Name">
                                    <el-input v-model="AddContractTemplateForm.Name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="金额" prop="Money">
                                    <el-input-number style="width :100%" v-model="AddContractTemplateForm.Money" 
                                    :precision="2" :step="0.01" :min="0" :max="50000"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="佣金比例" prop="CommissionPercentage">
                                   <el-input-number style="width :100%" v-model="AddContractTemplateForm.CommissionPercentage" 
                                   :precision="2" :step="0.01" :min="0" :max="1"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="起始日期：" prop="StartDate">
                                    <el-date-picker
                                        v-model="AddContractTemplateForm.StartDate"
                                        @change="AddDateChange"
                                        type="date"
                                        placeholder="起始日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                        style="width :100%">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="结束日期：" prop="EndDate">
                                    <el-date-picker
                                        v-model="AddContractTemplateForm.EndDate"
                                        @change="AddDateChange"
                                        type="date"
                                        placeholder="结束日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                        style="width :100%">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!--课包信息-->
                    <el-tab-pane label="课包信息" name="1">
                        <el-alert title="课包提交后将不可修改" type="info" center show-icon :closable="true"> </el-alert>
                        <el-row>
                            <el-form-item label="选择课包：" prop="CourseClassID">
                                <el-cascader style="display:table"
                                    v-model="CourseClassIDValue"
                                    :options="leveLAndCourseClassList"
                                    :props="DefaultParams"
                                    @change="CascaderChange">
                                </el-cascader>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="基础课时" prop="BasicClassCount">
                                   <el-input-number style="width :100%" v-model="AddContractTemplateForm.BasicClassCount" 
                                   :precision="0" :step="1" :min="0" :max="100"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="赠送课时" prop="FreeClassCount">
                                   <el-input-number style="width :100%" v-model="AddContractTemplateForm.FreeClassCount" 
                                   :precision="0" :step="1" :min="0" :max="100"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!--上课日期-->
                    <el-tab-pane label="上课日期" name="2">
                        <el-row>
                            <el-col :span=3>
                                <el-checkbox v-model="AddContractTemplateForm.Monday" :true-label=1 label="周一"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="AddContractTemplateForm.Tuesday" :true-label=1 label="周二"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="AddContractTemplateForm.Wednesday" :true-label=1 label="周三"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="AddContractTemplateForm.Thursday" :true-label=1 label="周四"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="AddContractTemplateForm.Friday" :true-label=1 label="周五"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="AddContractTemplateForm.Saturday" :true-label=1 label="周六"></el-checkbox>
                            </el-col>
                            <el-col :span=3>
                                <el-checkbox v-model="AddContractTemplateForm.Sunday" :true-label=1 label="周天"></el-checkbox>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!--合同文件-->
                    <el-tab-pane label="合同文件" name="3">
                        <el-alert title="文件提交后将不可修改" type="info" center show-icon :closable="true"> </el-alert>
                        <el-row>
                            <el-col>
                                <el-upload
                                :action="UploadImageUrl"
                                :on-remove="HandleRemove"
                                :on-preview="HandlePreview"
                                list-type="picture"
                                :headers="HeaderObj"
                                :on-success="UploadSuccess">
                                    <el-button type="primary">点击上传图片</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
                                </el-upload>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                <el-upload
                                style="margin:10px"
                                :action="UploadFileUrl"
                                :on-remove="HandleRemove"
                                list-type="text"
                                :headers="HeaderObj"
                                :on-success="UploadSuccess">
                                    <el-button type="primary">点击上传文件</el-button>
                                </el-upload>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-button type="primary" class="btn_add" @click="SubmitContractTemplate">添加合同</el-button>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
        </el-card>
        <el-dialog
        title="图像预览"
        :visible.sync="PreviewDialogVisible"
        width="50%">
        <img :src="ImagePreviewUrl" class="previewimage"/>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import {  Alert,Table,TabPane,InputNumber,DatePicker,Upload  } from 'element-ui';
Vue.use(Alert)
Vue.use(Table)
Vue.use(TabPane)
Vue.use(InputNumber)
Vue.use(DatePicker)
Vue.use(Upload)
export default {
    data(){
        return{
            PreviewDialogVisible:false,
            ImagePreviewUrl:'http://localhost:5000/ContractTemplateImage/ContractTemplateImage?filename=',
            UploadImageUrl:'http://localhost:5000/ContractTemplateImage/Upload',
            UploadFileUrl:'http://localhost:5000/ContractTemplateFile/Upload',
            CourseClassIDValue:'',
            leveLAndCourseClassList:[],
            DefaultParams:{
                label: 'name',
                disabled: 'disChild',
                value: 'id',
                children: 'courseClassFications',
                expandTrigger:'hover',
            }, 
            HeaderObj:{
                Authorization:sessionStorage.getItem('token')
            },
            ActiveIndex:'0',
            AddContractTemplateForm:{
                Code:'',
                Name:'',
                CourseClassID:'',
                Money:0.00,
                FileUrls:[],
                BasicClassCount:0,
                FreeClassCount:0,
                StartDate:'',
                EndDate:'',
                Monday:0,
                Tuesday:0,
                Wednesday:0,
                Thursday:0,
                Friday:0,
                Saturday:0,
                Sunday:0,
                CommissionPercentage:'',
            },
            AddContractTemplateFormRules:{
                Code:[
                    { required: true, message: '请输入合同模板编号', trigger: 'blur' }
                ],
                Name:[
                    { required: true, message: '请输入合同模板名称', trigger: 'blur' }
                ],
                Money:[
                    { required: true, message: '请输入金额', trigger: 'blur' }
                ],
                CommissionPercentage:[
                    { required: true, message: '请输入佣金比例', trigger: 'blur' }
                ],
                StartDate:[
                    { required: true, message: '请选择起始日期', trigger: 'blur' }
                ],
                EndDate:[
                    { required: true, message: '请选择结束日期', trigger: 'blur' }
                ],
                CourseClassID:[
                    { required: true, message: '请选择课包', trigger: 'blur' }
                ]
            }
        }
    },
    created(){

    },
    methods:{
        BeforeTabLeave(activeName,oldActiveName){
            console.log(oldActiveName)
            // if(oldActiveName === '0' ){
            //     if(this.AddContractTemplateForm.Code.trim().length === 0){
            //         this.$message.error('请填写合同编号')
            //         return false
            //     }
            //     if(this.AddContractTemplateForm.Name.trim().length === 0){
            //         this.$message.error('请填写合同名称')
            //         return false
            //     }
            //     if(this.AddContractTemplateForm.StartDate.trim().length === 0){
            //         this.$message.error('请选择合同起始时间')
            //         return false
            //     }
            //     if(this.AddContractTemplateForm.EndDate.trim().length === 0){
            //         this.$message.error('请选择合同结束时间')
            //         return false
            //     }
            // }  
            // else if(oldActiveName === "1"){
            //     if(this.AddContractTemplateForm.CourseClassID === '' || this.AddContractTemplateForm.CourseClassID === 0){
            //         this.$message.error('请填写合同课包')
            //         return false
            //     }
            // } 
        },
        TabClick(){
            if(this.ActiveIndex === '1'){
                this.GetLeveLAndCourseClassList()
            }
        },
        AddDateChange(){
            var starttime = new Date(this.AddContractTemplateForm.StartDate);
            var endtime = new Date(this.AddContractTemplateForm.EndDate);
            if(starttime >=endtime) 
            { 
                this.$alert('开始时间不能大于结束时间！', '提示', {
                    confirmButtonText: '确定'
                });
                return false; 
            }
        },
        async GetLeveLAndCourseClassList(){
            const {data:res} = await this.$http.get('LevelInfo/v1/LeveLAndCourseClass')
            if(res.code !== 1){
                return this.$message.error('获取课程列表失败');
            }
          
            this.leveLAndCourseClassList = res.data
              console.log(this.leveLAndCourseClassList)
        },
        CascaderChange(value){
            this.AddContractTemplateForm.CourseClassID = value[1]
        },
        HandleRemove(file){
            const filename =  file.name
            const index = this.AddContractTemplateForm.FileUrls.findIndex(x => x === filename)
            this.AddContractTemplateForm.FileUrls.splice(index,1)
        },
        UploadSuccess(response){
            response.data.forEach(element => {
                 this.AddContractTemplateForm.FileUrls.push(element)
            });
           
        },
        HandlePreview(file){
            this.ImagePreviewUrl = this.ImagePreviewUrl + file.name
            console.log(this.ImagePreviewUrl)
            this.PreviewDialogVisible = true
        },
        async SubmitContractTemplate(){
            this.$refs.AddContractTemplateFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            await this.$http.post('ContractTemplate/v1/ContractTemplateAndFile',this.AddContractTemplateForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    return this.$message.error('添加合同模板失败')
                }
            }).catch(function (error) {
                return this.$message.error(error)
            });
            this.validret = false
            this.$message.success('添加成功');
            this.$router.push('/ContractTList')
        }
    }
}
</script>